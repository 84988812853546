<template>
  <div class="w-full h-full flex flex-col items-center gap-y-1 justify-center edu-com-placeholder-action">
    <label class="placeholder-action-label"> {{label}} </label>
    <slot>
      <div
        class="flex gap-x-2 items-center placeholder-action-container"
        @click="action"
        v-if="!noAction"
        style="cursor: pointer;"
      >
        <div>
          <feather-icon
            :icon="actionIcon"
            svgClasses="h-4 w-4 placeholder-action-icon-svg"
            class="ml-1 placeholder-action-icon"
            v-tooltip.top="{
              content: actionTooltip,
              delay: { show: 1000, hide: 100 }
            }"
          />
        </div>
        <div>
          <b class="placeholder-action-icon-text">{{actionText}}</b>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: function () {
        return this.$t('nao-ha-nada-aqui')
      }
    },
    actionTooltip: {
      type: String,
      default: function () {
        return this.$t('atualizar')
      }
    },
    actionText: {
      type: String,
      default: function () {
        return this.$t('atualizar')
      }
    },
    actionIcon: {
      type: String,
      default: 'RefreshCwIcon'
    },
    noAction: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    action(event) {
      this.$emit('action', event)
    }
  }
}
</script>