<template>
  <div>

      <vs-alert
        v-if="videoProcessing"
        :title="$t('atencao')"
        color="rgb(231, 154, 23)"
        active="true"
        class="text-warning"
      >
        {{ $t('video-ainda-esta-em-processamento') }}
      </vs-alert>

      <div class="vjs-custom-skin">
        <video
          ref="videoPlayer"
          class="video-js"
          playsinline="true"
          :src="options.src"
        >
          <source :src="options.src" :type="options.type"/>
        </video>
      </div>

      <edu-placeholder-action v-if="videoWaitProcessing" @action="$router.go()" :label="$t('o-video-ainda-esta-sendo-processado')" class="absolute z-50 placeholder-backdrop text-white bottom-0"/>
  </div>
</template>

<script>
import EduPlaceholderAction from '@/components/EduPlaceholderAction.vue'

import 'video.js/dist/video-js.min.css'

export default {
  components: {
    EduPlaceholderAction
  },
  props: {
    options: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data: function() {
    return {
      playerOptions: {
        autoplay: false,
        controls: true,
        controlBar: {
          currentTimeDisplay: true,
          timeDivider: true,
          durationDisplay: true,
          liveDisplay: false
        },
        liveui: false,
      }
    }
  },
  computed: {
    player () {
      return this.$refs.videoPlayer
    },
    videoRendered () {
      return this.options.video_progress >= 100
    },
    videoProcessing () {
      console.log('this.options.video_progress', this.options.video_progress);
      return this.options.video_progress >= 0 && this.options.video_progress < 100
    },
    videoWaitProcessing () {
      return this.isEmpty(this.options.video_progress)
    }
  },
  methods: {
    setupVideo: function () {
      if (!this.videoRendered) this.playerOptions.autoplay = false
      this.videoPlayer = this.$video(this.player, this.playerOptions)

      this.videoPlayer.controlBar.progressControl.el_.classList.add('grid')
      this.videoPlayer.controlBar.progressControl.el_.classList.add('grid-rows-2')
      this.videoPlayer.controlBar.progressControl.addChild('eventBarCustom', {events: this.options.events})

    }
  },
  mounted() {
    console.log('this.options.src', this.options.src)
    this.setupVideo()
  },
  beforeDestroy() {
    if (this.videoPlayer) {
      this.videoPlayer.dispose()
    }
  }
}
</script>

<style lang="scss">

@import './Player.scss';

.video-js {
  position: inherit;
  height: 480px;
  width: 100%;
}

  /* Estiliza o tooltip */
  .tooltip {
    position: relative;
    display: inline-block;
  }

  /* O conteúdo do tooltip */
  .tooltip .tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    border-radius: 6px;
    padding: 5px 0;
    min-width: 300px;

    /* Posiciona o tooltip */
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;

    /* Fade-in e fade-out efeitos */
    opacity: 0;
    transition: opacity 0.3s;
  }

  /* Mostra o tooltip quando o mouse passa sobre a div */
  .tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }

  .tooltip .tooltip-text-content {
    padding: 5px;
    text-align: left;
  }

</style>
