var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "w-full h-full flex flex-col items-center gap-y-1 justify-center edu-com-placeholder-action",
    },
    [
      _c("label", { staticClass: "placeholder-action-label" }, [
        _vm._v(" " + _vm._s(_vm.label) + " "),
      ]),
      _vm._t("default", function () {
        return [
          !_vm.noAction
            ? _c(
                "div",
                {
                  staticClass:
                    "flex gap-x-2 items-center placeholder-action-container",
                  staticStyle: { cursor: "pointer" },
                  on: { click: _vm.action },
                },
                [
                  _c(
                    "div",
                    [
                      _c("feather-icon", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip.top",
                            value: {
                              content: _vm.actionTooltip,
                              delay: { show: 1000, hide: 100 },
                            },
                            expression:
                              "{\n            content: actionTooltip,\n            delay: { show: 1000, hide: 100 }\n          }",
                            modifiers: { top: true },
                          },
                        ],
                        staticClass: "ml-1 placeholder-action-icon",
                        attrs: {
                          icon: _vm.actionIcon,
                          svgClasses: "h-4 w-4 placeholder-action-icon-svg",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", [
                    _c("b", { staticClass: "placeholder-action-icon-text" }, [
                      _vm._v(_vm._s(_vm.actionText)),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }